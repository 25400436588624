import React from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import { connect } from "react-redux";
import { serverLink } from "../../resources/url";
import { Link } from "react-router-dom";
import * as DOMPurify from 'dompurify';

function About(props) {
  return (
    <>
      <PageName page={"About Us"} />
      <PageTitle title={"About Us"} breadCrumbs={["Home", "About"]} />
      <div className="container mt-5">
        <div className="row  mb-5">
          <div className="col-lg-8" style={{ textAlign: "justify" }}>
            <h2>About the University</h2>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.homeData.AboutContent) }}
            />
          </div>
          <div className="col-lg-4">
            <div className="additional-information">
              <h3>Additional Information</h3>
              <div className="list">
                <ul>
                  <li>
                    <Link to="/our-campus">Our Campus</Link>
                  </li>
                  <li>
                    <Link to="/admission/course-finder">Courses</Link>
                  </li>
                  <li>
                    <Link to="/admission/application">Application</Link>
                  </li>
                  <li>
                    <Link to="/education">Education</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    homeData: state.HomepageContentDetails,
  };
};

export default connect(mapStateToProps, null)(About);
