import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  formatDateAndTime,
  projectEmail,
  projectPhone,
  serverLink,
} from "../../../../resources/url";
import PageTitle from "../../../common/pagetitle/pagetitle";
import PageName from "../../../common/pagename/pagename";
import Loader from "../../../common/loader/loader";
import axios from "axios";

function ApplicationLandingPage(props) {
  const [dateline, setDateline] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDateline = async () => {
    await axios
      .get(`${serverLink}application/dateline`)
      .then((res) => {
        setDateline(res.data.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
      });
  };

  useEffect(() => {
    getDateline();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <>
      <PageName page={"Application Guide"} />
      <PageTitle
        title={"Application Guide"}
        breadCrumbs={["Home", "Admission", "Application Guide"]}
      />

      <div className="graduate-admission pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8" style={{ textAlign: "justify" }}>
              <div className="how-to-apply">
                <h2>How To Apply</h2>
                <p>
                  The application process for entry into the programmes offered
                  by our university are relatively similar across all
                  departments and faculties. This seamless and simplified
                  process is geared towards ensuring that our applicants are
                  comfortable regardless of the level of their computing
                  literacy.
                </p>
                <div className="apply-list">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h4>Before You Apply</h4>
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Find a Course
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Register
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" /> Submit Your
                          Application Online
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h4>After You Applied</h4>
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Login
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Track Your Application
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Download Your Admission Letter
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>
                  You can submit multiple applications to different courses, but
                  please do not accept any offer until you have received a
                  decision from all the programmes you have applied for. When
                  you have received all decisions from the university please
                  make sure you accept only one offer as soon as possible.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="additional-information">
                <h3>Apply Now</h3>
                {dateline.IsAlwaysOpen < 1 && (
                  <div className="alert alert-warning">
                    Application Dateline:{" "}
                    {formatDateAndTime(dateline.EndDate, "date")}
                  </div>
                )}
                <div className="list">
                  <ul>
                    <Link to={"/admission/application/register"}>
                      <li>Register</li>
                    </Link>
                    <Link to={"/admission/application/login"}>
                      <li>Login</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="related-download">
                <h3>Contact Us</h3>
                <ul>
                  <li>
                    <a href={`tel:${projectPhone}`}>
                      <i className="flaticon-phone-call" />
                      {projectPhone}
                    </a>
                  </li>
                  <li>
                    <a href={`mailto:${projectEmail}`}>
                      <i className="flaticon-email" />
                      Send us mail
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.courseList.length > 0 && (
        <div className="program-area bg-f4f6f9 pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Applying To Other Programs</h2>
            </div>
            <div className="row justify-content-center">
              {props.courseList.map((item, index) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                    <div className="single-courses-card style4">
                      <div className="courses-content">
                        <Link to={`/course/${item.Slug}`}>
                          <h3>{item.CourseName}</h3>
                        </Link>
                        <div className="bottom-content">
                          <ul className="d-flex justify-content-between">
                            <li>
                              <ul>
                                <li>
                                  <i className="fa fa-clock-o" />
                                  {item.Duration} {item.DurationType}
                                </li>
                                <li>
                                  <i className="flaticon-student-with-graduation-cap" />
                                  {item.CourseClass}
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to={`/course/${item.Slug}`}>
                                {item.CourseCode}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails,
  };
};
export default connect(mapStateToProps, null)(ApplicationLandingPage);
