import React from "react";
import {serverLink, shortCode} from "../../../../resources/url";
import imgF from "../../../../images/event.jpg";
import { formatDate } from "../../../common/dateformat/dateformat";
import * as DOMPurify from 'dompurify';

export default function NewsDetailsContent(props) {

return <div className="col-lg-8">
        <div className="news-details">
            <div className="news-simple-card">
                <img className="img-fluid img-thumbnail w-100"
                    src={props.IsNews ? `${props.newsData?.ImagePath !== "" ?
                        props.newsData?.ImagePath?.includes("simplefileupload") ? props.newsData?.ImagePath :
                            `${serverLink}public/uploads/${shortCode}/news_uploads/${props.newsData?.ImagePath}` : imgF}`
                    :
                        `${props.newsData?.ImagePath !== "" ?
                            props.newsData?.ImagePath?.includes("simplefileupload") ? props.newsData?.ImagePath :
                                `${serverLink}public/uploads/${shortCode}/event_uploads/${props.newsData?.ImagePath}` : imgF}`
                    }

                    alt="Image" />
                <div className="list">
                    <ul>
                        <li><i className="flaticon-user" />By {props.newsData?.InsertedBy}</li>
                        <li><i className="flaticon-tag" />{props.newsData?.FacultyCode}</li>
                    </ul>
                </div>
                <h3>{props.newsData?.Title}</h3>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.newsData?.Description) }} />
            </div>

            <div className="tags-and-share mt-4">
                {props.IsNews === true ? "" :
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <div className="tags">
                                <ul>
                                    <li><span>Event Date:</span></li>
                                    <li>{formatDate(props.newsData?.EventDate)}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="share">
                                <ul>
                                    <li><span>Event Time:</span></li>
                                    <li>{props.newsData?.StartTime} - {props.newsData?.EndTime}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <div className="tags">
                                <ul>
                                    <li><span>Location:</span></li>
                                    <li>{props.newsData?.Location} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <hr />
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="tags">
                            <ul>
                                <li><span>Tags :</span></li>
                                <li><a href="admission.html">Admission</a></li>
                                <li><a href="admission.html">Research</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="share">
                            <ul>
                                <li><span>Share :</span></li>
                                <li>
                                    <a href="https://www.facebook.com/" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-facebook" /></a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-twitter" /></a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/?lang=en" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-instagram" /></a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/?lang=en" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-linkedin" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

