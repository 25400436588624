import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import "./navigationbar.css";
import Logo from "../../../images/logo.png";
import { setFacultyListDetails } from "../../../actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import {projectName, serverLink} from "../../../resources/url";

function NavigationBar(props) {
  const location = useLocation();
  const [mobileNav, setMobileNav] = useState(false);
  const onToggleNav = () => { setMobileNav(!mobileNav)};

  const [mobileAboutNav, setMobileAboutNav] = useState(false);
  const onToggleAboutNav = (e) => {setMobileAboutNav(!mobileAboutNav);};

  const [mobileAdmissionNav, setMobileAdmissionNav] = useState(false);
  const onToggleAdmissionNav = () => {
    setMobileAdmissionNav(!mobileAdmissionNav);
  };

  const [mobileAcademicsNav, setMobileAcademicsNav] = useState(false);
  const onToggleAcademicsNav = () => {
    setMobileAcademicsNav(!mobileAcademicsNav);
  };

  const [mobileResourcesNav, setMobileResourcesNav] = useState(false);
  const onToggleResourcesNav = () => {
    setMobileResourcesNav(!mobileResourcesNav);
  };

  useEffect(() => {
    setMobileNav(false)
  }, [location.pathname]);

  const getFacultyList = async () => {
    await axios
      .get(`${serverLink}academics/faculty/academic/list`)
      .then((response) => {
        props.setOnFacultyListDetails(response.data);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };
  useEffect(() => {
    getFacultyList();
  }, []);

  const handleClick = () => {
    console.log('100000')
  }

  return (
    <div className="navbar-area nav-bg-2">
      <div className="mobile-responsive-nav">
        <div className="container">
          <div className="mobile-responsive-menu mean-container">
            <div className="mean-bar">
              <div className="meanmenu-reveal" onClick={onToggleNav} style={{right: "0px", left: "auto", textAlign: "center", textIndent: "0px", fontSize: "18px",}}>
                <span>
                  <span>
                    <span />
                  </span>
                </span>
              </div>
              <nav className="mean-nav">
                <ul className="navbar-nav ms-auto" style={{ display: `${mobileNav ? 'block' : 'none'}` }}>
                  <li className="nav-item">
                    <Link to={"/"} className="nav-link">
                      Home
                    </Link>
                  </li>

                  <li className="nav-item" onClick={() => onToggleAboutNav('main')}>
                    <Link to="" className="nav-link dropdown-toggle">
                      About
                    </Link>
                    <ul className="dropdown-menu" style={{ display: `${mobileAboutNav ? 'block' : 'none'}` }}>
                      <li className="nav-item">
                        <Link to={"/about-us"}>About Us</Link>
                      </li>

                      <li className="nav-item">
                        <Link to={"/our-campus"}>Our Campus</Link>
                      </li>

                      <li className="nav-item">
                        <Link to={"/gallery"}>Gallery</Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/board-members" className="nav-link">
                          Board
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/management" className="nav-link">
                          Management
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/senate" className="nav-link">
                          Senate
                        </Link>
                      </li>

                    </ul>
                    <Link to={""}
                      className="mean-expand"
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </Link>
                  </li>
                  <li className="nav-item" onClick={onToggleAdmissionNav}>
                    <Link to="" className="nav-link dropdown-toggle">
                      Admission
                    </Link>
                    <ul className="dropdown-menu" style={{ display: `${mobileAdmissionNav ? 'block' : 'none'}` }}>
                      <li className="nav-item">
                        <Link to="/admission/course-finder" className="nav-link">
                          Course Finder
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/fees" className="nav-link">
                          Fees
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/admission/application" className="nav-link">
                          Application
                        </Link>
                      </li>
                      {/*<li className="nav-item">*/}
                      {/*  <Link to="/payment" className="nav-link">*/}
                      {/*    Payment*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                    </ul>
                    <Link
                      className="mean-expand"
                      to=""
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </Link>
                  </li>
                  <li className="nav-item" onClick={onToggleAcademicsNav}>
                    <Link to="" className="nav-link dropdown-toggle">
                      Academics
                    </Link>
                    <ul className="dropdown-menu" style={{ display: `${mobileAcademicsNav ? 'block' : 'none'}` }}>
                      {props.facultyList.length > 0 &&
                          props.facultyList.map((faculty, index) => {
                            return (
                                <li key={index} className="nav-item">
                                  <Link to={`/faculty/${faculty.Slug}`}>
                                    {faculty.FacultyName}
                                  </Link>
                                </li>
                            );
                          })}
                      <li className="nav-item">
                        <Link to="/staff-list" className="nav-link">
                          Staff
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/education" className="nav-link">
                          Education
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/learning-goal" className="nav-link">
                          Learning Goal
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/academic-registry" className="nav-link">
                          Academic Registry
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/academic-calendar" className="nav-link">
                          Academic Calendar
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className="mean-expand"
                      to=""
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </Link>
                  </li>
                  <li className="nav-item" onClick={onToggleResourcesNav}>
                    <Link to="" className="nav-link dropdown-toggle">
                      Resources
                    </Link>
                    <ul className="dropdown-menu" style={{ display: `${mobileResourcesNav ? 'block' : 'none'}` }}>
                      <li className="nav-item">
                        <Link to="/press" className="nav-link">
                          {projectName} In The Press
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/news" className="nav-link">
                          News
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/event" className="nav-link">
                          Event
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/publication" className="nav-link">
                          Publication
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/jobs" className="nav-link">
                          Careers
                        </Link>
                      </li>
                    </ul>
                    <Link
                        className="mean-expand"
                        to=""
                        style={{ fontSize: "18px" }}
                    >
                      +
                    </Link>
                  </li>
                  <li className="nav-item mean-last">
                    <Link to={"contact"} className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="others-options">
                  <div className="icon">
                    <i
                      className="ri-menu-3-fill"
                      data-bs-toggle="modal"
                      data-bs-target="#sidebarModal"
                    />
                  </div>
                </div>
              </nav>
            </div>
            <div className="logo">
              <Link to="/">
                <img src={Logo} className="main-logo" alt={projectName} />
                <img src={Logo} className="white-logo" alt={projectName} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-nav">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link to={"/"} className="navbar-brand">
              <img src={Logo} alt="logo" className="project-logo" />
            </Link>

            <div
              className="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className={`nav-item`}>
                  <Link to={"/"} className="nav-link">
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <a href="#" className="nav-link dropdown-toggle">
                    About
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to={"/about-us"}>About Us</Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/our-campus"}>Our Campus</Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/gallery"}>Gallery</Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/board-members"
                        className="nav-link dropdown-toggle"
                      >
                        Leadership
                      </Link>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to="/board-members" className="nav-link">
                            Board
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/management" className="nav-link">
                            Management
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/senate" className="nav-link">
                            Senate
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link dropdown-toggle">
                    Admission
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to="/admission/course-finder" className="nav-link">
                        Course Finder
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/fees" className="nav-link">
                        Fees
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/admission/application" className="nav-link">
                        Application
                      </Link>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*  <Link to="/payment" className="nav-link">*/}
                    {/*    Payment*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="#" className="nav-link dropdown-toggle">
                    Academics
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to="#" className="nav-link dropdown-toggle">
                        Faculties
                      </Link>
                      <ul className="dropdown-menu">
                        {props.facultyList.length > 0 &&
                          props.facultyList.map((faculty, index) => {
                            return (
                              <li key={index} className="nav-item">
                                <Link to={`/faculty/${faculty.Slug}`}>
                                  {faculty.FacultyName}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>

                    <li className="nav-item">
                      <Link to="/staff-list" className="nav-link">
                        Staff
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/education" className="nav-link">
                        Education
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/learning-goal" className="nav-link">
                        Learning Goal
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/academic-registry" className="nav-link">
                        Academic Registry
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/academic-calendar" className="nav-link">
                        Academic Calendar
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="" className="nav-link dropdown-toggle">
                    Resources
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to="/press" className="nav-link">
                        {projectName} In The Press
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/news" className="nav-link">
                        News
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/event" className="nav-link">
                        Event
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/publication" className="nav-link">
                        Publication
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/jobs" className="nav-link">
                        Careers
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <div className="others-options">
                <div className="icon">
                  <i
                    className="ri-menu-3-fill"
                    data-bs-toggle="modal"
                    data-bs-target="#sidebarModal"
                  />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="others-option-for-responsive">
        <div className="container">
          <div className="dot-menu">
            <div className="inner">
              <div className="icon">
                <i
                  className="ri-menu-3-fill"
                  data-bs-toggle="modal"
                  data-bs-target="#sidebarModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
