import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  formatDateAndTime,
  projectAddress,
  projectName,
  projectURL,
  sendEmail,
  serverLink, shortCode,
} from "../../../../../resources/url";
import { useNavigate } from "react-router-dom";

function PGApplicationPreview(props) {
  const navigate = useNavigate();
  const onPrevious = () => {
    props.active_page({
      document: "active",
      preview: "",
    });
  };

  const onSubmit = async () => {
    if (props.application_id) {
      toast.info(`Please wait while submitting your application...`);
      await axios
        .patch(
          `${serverLink}application/pg/update/status/${props.application_id}`
        )
        .then((res) => {
          if (res.data.message === "success") {
            props.update_app_data();
            toast.success(`Application submitted successfully`);

            sendEmail(
              props.applicant_data.information[0].EmailAddress,
              `Application for ${props.app_record.CourseName}`,
              "Application Submitted",
              `${props.applicant_data.information[0].FirstName} ${props.applicant_data.information[0].MiddleName} ${props.applicant_data.information[0].Surname}`,
              `Thank you for applying for a place in the ${props.app_record.CourseName} programme. <br/> 
                Provided your application is complete and includes the required documents, we aim to give you a decision on your application by the relevant decision date`,
              `Best Regards <br/> Admission Team <br/> ${projectURL}`
            );
            navigate("/admission/application/dashboard");
          } else {
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  let passport = props.applicant_data.document.filter((item) =>
    item.DocumentType.includes("Passport")
  );

  return (
    <>
      {props.requiredDoc &&
      props.applicant_data.employment &&
      props.applicant_data.qualification.length ? (
        <>
          <div className="pt-3">
            <div
              className="header"
              style={{
                backgroundColor: "#e0e0e0",
                color: "black",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "12px",
                  color: "black",
                }}
              >
                <h5
                  style={{
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  {projectName}
                </h5>
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginButton: "50px",
                    lineHeight: "1.6",
                  }}
                >
                  {projectAddress} <br />
                  Applicant Submission Summary
                </p>
              </div>
            </div>

            <div className="pt-4">
              <div
                className=""
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "black",
                  height: "40px",
                }}
              >
                <h6
                  className="pt-2 p-lg-2"
                  style={{
                    color: "black",
                  }}
                >
                  Personal Information
                </h6>
              </div>

              <div className="row pt-2">
                <div className="col-lg-3 col-sm-3">
                  <img
                    src={ passport.length > 0 ? passport[0].FileName !== null ? passport[0].FileName.includes("simplefileupload") ? passport[0].FileName :  `${serverLink}public/uploads/${shortCode}/application/document/${passport[0].FileName}` : ""   : "https://via.placeholder.com/150"} 
                    // {
                    //   passport.length > 0
                    //     ? `${serverLink}public/uploads/${shortCode}/application/document/${passport[0].FileName}`
                    //     : "https://via.placeholder.com/150"
                    // }
                    height={150}
                    alt="Applicant Passport"
                  />
                </div>
                <div className="col-lg-9 col-sm-9">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">Applicant ID:</th>
                        <td>#{props.applicant_data.information[0].EntryID}</td>
                      </tr>
                      <tr>
                        <th scope="row">Names</th>
                        <td>
                          {props.applicant_data.information[0].FirstName} {""}
                          {props.applicant_data.information[0].MiddleName} {""}
                          {props.applicant_data.information[0].Surname} {""}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Phone Number</th>
                        <td>
                          {props.applicant_data.information[0].PhoneNumber}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Email</th>
                        <td>
                          {props.applicant_data.information[0].EmailAddress}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="pt-3">
              <div
                className=""
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "black",
                  height: "40px",
                }}
              >
                <h6
                  className="pt-2 p-lg-2"
                  style={{
                    color: "black",
                  }}
                >
                  Qualification
                </h6>
              </div>

              <div className="pt-4">
                {props.applicant_data.qualification ? (
                  <>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Qualification Title</th>
                          <th scope="col">Qualification Name</th>
                          <th scope="col">Awarding Institution</th>
                          <th scope="col">Grade</th>
                          <th scope="col">Year Awarded</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.applicant_data.qualification.length > 0 &&
                          props.applicant_data.qualification.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item.QualificationTitle}</td>
                                <td>{item.QualificationName}</td>
                                <td>{item.AwardingInstitution}</td>
                                <td>{item.GradeObtained}</td>
                                <td>
                                  {formatDateAndTime(
                                    item.DateAwarded.split("T")[0],
                                    "date"
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="alert alert-info">There is no record.</div>
                  </>
                )}
              </div>
            </div>

            <div className="pt-3">
              <div
                className=""
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "black",
                  height: "40px",
                }}
              >
                <h6
                  className="pt-2 p-lg-2"
                  style={{
                    color: "black",
                  }}
                >
                  Employment History
                </h6>
              </div>

              <div className="pt-4">
                {props.applicant_data.employment ? (
                  <>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Designation</th>
                          <th scope="col">Employer</th>
                          <th scope="col">Employer Address</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.applicant_data.employment.length > 0 &&
                          props.applicant_data.employment.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Designation}</td>
                              <td>{item.Employer}</td>
                              <td>{item.EmployerAddress}</td>
                              <td>
                                {formatDateAndTime(
                                  item.StartDate.split("T")[0],
                                  "date"
                                )}
                              </td>
                              <td>
                                {item.EndDate !== null
                                  ? formatDateAndTime(
                                      item.EndDate.split("T")[0],
                                      "date"
                                    )
                                  : "active"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="alert alert-info">There is no record.</div>
                  </>
                )}
              </div>
            </div>

            <div className="pt-3">
              <div
                className=""
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "black",
                  height: "40px",
                }}
              >
                <h6
                  className="pt-2 p-lg-2"
                  style={{
                    color: "black",
                  }}
                >
                  Supporting Documents
                </h6>
              </div>

              <div className="pt-3">
                {props.applicant_data.document ? (
                  <>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Document Type</th>
                          <th>File Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.applicant_data.document.length > 0 &&
                          props.applicant_data.document.map((item, index) => (
                            <tr key={index}>
                              <td>{item.DocumentType}</td>
                              <td>
                                <a
                                  target="_blank"
                                  referrerPolicy="no-referrer"
                                  href={ item.FileName !== null ? item.FileName.includes("simplefileupload") ? item.FileName :  `${serverLink}public/uploads/${shortCode}/application/document/${item.FileName}` : "" } 
                                >
                                  <i className="fa fa-file-pdf-o" />
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="alert alert-info">There is no record.</div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <button
                className="default-btn btn w-100 active"
                onClick={onPrevious}
              >
                Previous
              </button>
            </div>
            <div className="col-lg-6 col-sm-6">
              <button
                className="default-btn btn w-100 active"
                onClick={onSubmit}
              >
                Submit Application
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="alert alert-info">
            Form not completed. Kindly complete the application and come back to
            this page for submission
          </div>
          <div className="col-lg-6 col-sm-6">
            <button
              className="default-btn btn w-100 active"
              onClick={onPrevious}
            >
              Previous
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default PGApplicationPreview;
