import React from "react";
import {formatDateAndTime, serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";

export default function SectionUniversityMission() {
    return (
        <div className="events-area  ptb-50">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 shadow">
                        <div className="single-events-card">
                            <div className="events-content">
                                <div className="mission">
                                    <p>
                                        <i className="fa fa-area-chart fa-4x"/>
                                    </p>
                                </div>
                                <h4>Mission</h4>
                                <p style={{textAlign: "justify"}}>
                                    The mission of Olivia University Bujumbura is to provide an excellent teaching and
                                    learning environment suited for training, research and service in medical,
                                    engineering and allied fields for the benefit of mankind, today and in the future.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 shadow">
                        <div className="single-events-card">
                            <div className="events-content">
                                <div className="mission">
                                    <p>
                                        <i className="fa fa-eye fa-4x"/>
                                    </p>
                                </div>
                                <h4>Vision</h4>
                                <p style={{textAlign: "justify"}}>
                                    The vision of Olivia University Bujumbura is to be a modern, top-notch, ICT – driven
                                    University of Medical and Engineering Sciences, among others, guided by principles
                                    of excellence, professionalism, ethics, innovation and research.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 shadow">
                        <div className="single-events-card">
                            <div className="events-content">
                                <div className="mission">
                                    <p>
                                        <i className="fa fa-institution fa-4x"/>
                                    </p>
                                </div>
                                <h4>History</h4>
                                <p style={{textAlign: "justify"}}>
                                    The historical background for the establishment of OUB finds expression within the
                                    context of the founder's existential reality. Sir Paul Chukwuma, the founder and
                                    Pro-chancellor of OUB is fortunate to have been born into a strong Catholic family.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
