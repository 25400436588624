import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import { Link } from "react-router-dom";
import fussball from "../../../images/fussball.jpg";
import television from "../../../images/tvarea.jpg";
import tennis from "../../../images/tennis.jpg";
import PageName from "../../common/pagename/pagename";
export default function Feeding() {
  return (
    <>
      <PageName page={"Accommodation"} />
      <PageTitle
        title={` Accommodation `}
        breadCrumbs={["Admission", "Fees", "Accommodation"]}
      />
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-4">Accommodation</h3>
              <p style={{ textAlign: "justify" }}>
                Students can either use accommodation provided by the
                University, live at home, or arrange their own accommodation. In
                terms of proximity and ease of access to university resources,
                the campus accommodation is the preferable option due to the
                location of the university.
              </p>
              <h3 className="mb-2">Campus Accommodation and Cost</h3>
              <p style={{ textAlign: "justify" }}>
                Providing a space that serves as a home away from home is our
                priority at Baba Ahmed University. Our campus accommodation
                options are designed with the utmost consideration for the
                students’ welfare. Students will be housed in either one-person,
                or two-person per rooms hostel accommodation. The cost will
                depend on the number of students sharing, and the nature of the
                facilities. Private accommodation (one person per room) costs
                N715,412.50* per semester. Shared accommodation (two persons per
                room) costs N357,706.25* per semester.
              </p>
              <b className="mb-2">
                Students are strongly advised to confirm the availability of
                Accommodation in the <a href="">Hostel Portal</a> before making
                payment.
              </b>
              <p className="mt-2" style={{ textAlign: "justify" }}>
                Each room is provided with study desks and reading lamps to
                ensure that students have comfortable spaces for focusing on
                their studies in their living accommodations. Refrigerators and
                quality furniture are also provided to create the perfect
                home-like environment for our students.
              </p>
              <h3 className="mt-4">Other Facilities</h3>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <img
                        src={fussball}
                        style={{ width: "100%" }}
                        alt="Fussball"
                      />
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Fussball</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <img
                        src={television}
                        style={{ width: "100%" }}
                        alt="Television Area"
                      />
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Television Area</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <img
                        src={tennis}
                        style={{ width: "100%" }}
                        alt="Indoor Tennis"
                      />
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Indoor Tennis</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="detgails-right-content">
                <div className="academics-list">
                  <h3>QUICK LINKS</h3>
                  <ul>
                    <li>
                      <Link to={`/fees`}>
                        Fees <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/admission/course-finder`}>
                        Course Finder <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/education`}>
                        Education <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
