import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import
{
  setCourseDataDetails,
  setCourseListDetails,
} from "../../../actions/setactiondetails";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { serverLink, shortCode } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import PageName from "../../common/pagename/pagename";
import * as DOMPurify from 'dompurify';

function CourseDetails(props)
{
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  if (slug === "") navigate("/admission/application");

  const [loading, setLoading] = useState(false);
  let courseList = props.courseList.filter((item) => item.Slug === slug);
  const [courseDetail, setCourseDetail] = useState(
    courseList.length > 0
      ? props.courseDetails.filter(
        (item) => item.CourseCode === courseList[0].CourseCode
      )
      : []
  );

  useEffect(() =>
  {
    const slug_record =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    courseList.push(
      props.courseList.filter((item) => item.Slug === slug_record)
    );
    setCourseDetail(
      props.courseDetails.filter(
        (item) => item.CourseCode === courseList[0].CourseCode
      )
    );
  }, [location.pathname]);

  const getCourseData = async () =>
  {
    console.log("helll");
    await axios
      .get(`${serverLink}academics/course/view/list`)
      .then((response) =>
      {
        const data = response.data;
        const course_data = data.filter((item) => item.Slug === slug);
        props.setOnCourseListDetail(response.data);
        if (data.filter((item) => item.Slug === slug).length < 1)
        {
          navigate("/admission/application");
        } else
        {
          courseList.push(course_data);
        }
      })
      .catch((error) =>
      {
        console.log("NETWORK ERROR", error);
      });

    await axios
      .get(`${serverLink}academics/course/details`)
      .then((response) =>
      {
        props.setOnCourseData(response.data);
        setCourseDetail(
          response.data.filter(
            (item) => item.CourseCode === courseList[0].CourseCode
          )
        );
        if (courseDetail.length === 0) navigate("/admission/application");
      })
      .catch((error) =>
      {
        console.log("NETWORK ERROR", error);
      });
  };

  useEffect(() =>
  {
    getCourseData();
  }, [courseDetail.length === 0]);

  return courseDetail.length < 1 ? (
    <Loader />
  ) : (
    <>
      <PageName page={courseList[0].CourseName} />
      <div className="courses-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="courses-details">
                <div className="courses-card">
                  <div className="img">
                    <img
                      src={courseDetail[0].ImagePath !== null ? courseDetail[0].ImagePath.includes("simplefileupload") ? courseDetail[0].ImagePath : `${serverLink}public/uploads/${shortCode}/course_uploads/${courseDetail[0].ImagePath}` : ""}

                      alt="Image"
                    />
                  </div>
                  <h2>{courseList[0].CourseName}</h2>
                </div>
                <div className="description">
                  <div className="container p-0">
                    <nav>
                      <div
                        className="nav nav-tabs d-flex justify-content-between"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="nav-overview-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-overview"
                          type="button"
                          role="tab"
                          aria-controls="nav-overview"
                          aria-selected="true"
                        >
                          Overview
                        </button>
                        <button
                          className="nav-link"
                          id="nav-curriculum-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-curriculum"
                          type="button"
                          role="tab"
                          aria-controls="nav-curriculum"
                          aria-selected="false"
                        >
                          Curriculum
                        </button>
                        <button
                          className="nav-link"
                          id="nav-instructor-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-instructor"
                          type="button"
                          role="tab"
                          aria-controls="nav-instructor"
                          aria-selected="false"
                        >
                          Staff
                        </button>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="nav-overview"
                        role="tabpanel"
                        aria-labelledby="nav-overview-tab"
                      >
                        <div className="overview">
                          <div
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(courseDetail[0].Description),
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-curriculum"
                        role="tabpanel"
                        aria-labelledby="nav-curriculum-tab"
                      >
                        <div className="curriculum">
                          <div className="alert alert-info">Coming Soon</div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-instructor"
                        role="tabpanel"
                        aria-labelledby="nav-instructor-tab"
                      >
                        <div className="row">
                          <div className="instructor">
                            <div className="alert alert-info">Coming Soon</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="enroll-courses">
                <div className="list">
                  <ul>
                    <li>
                      <span>Code :</span>
                      {courseList[0].CourseCode}
                    </li>
                    <li>
                      <span>Duration :</span>
                      {`${courseList[0].Duration} ${courseList[0].DurationType}`}
                    </li>
                    <li>
                      <span>Degree In View :</span>
                      {courseList[0].DegreeInView}
                    </li>
                    <li>
                      <span>Course Fee :</span>
                      <strong>NGN {courseList[0].TuitionFee}</strong> per year
                    </li>
                    <li>
                      <span>Department :</span>
                      {courseList[0].DepartmentName}
                    </li>
                    <li>
                      <Link
                        to={`/faculty/${courseList[0].FacultyName.toLowerCase()
                          .trim()
                          .replaceAll(" ", "-")}`}
                      >
                        {courseList[0].FacultyName}
                      </Link>
                    </li>
                  </ul>
                </div>
                <Link to={"/admission/application"} className="default-btn btn">
                  Apply Now
                </Link>
              </div>
              <div className="related-download">
                <h3>Related Courses</h3>
                <ul>
                  {props.courseList.filter(
                    (item) => item.FacultyCode === courseList[0].FacultyCode
                  ).length > 0 &&
                    props.courseList
                      .filter(
                        (item) => item.FacultyCode === courseList[0].FacultyCode
                      )
                      .map((course, index) =>
                      {
                        if (course.CourseCode !== courseList[0].CourseCode)
                        {
                          return (
                            <li key={index}>
                              <Link to={`/course/${course.Slug}`}>
                                <i className="fa fa-book" />
                                {course.CourseName}
                              </Link>
                            </li>
                          );
                        }
                      })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) =>
{
  return {
    courseList: state.CourseListDetails,
    courseDetails: state.CourseDataDetails,
    departmentList: state.DepartmentListDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
{
  return {
    setOnCourseListDetail: (p) =>
    {
      dispatch(setCourseListDetails(p));
    },
    setOnCourseData: (p) =>
    {
      dispatch(setCourseDataDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
