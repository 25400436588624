import React from "react";
import { Link } from "react-router-dom";
import imgF from "../../../../images/event.jpg";
import {serverLink, shortCode} from "../../../../resources/url";
import imgFile from "../../../../images/event.jpg";

export default function EventSidebar(props) {
  const bottomClicked = async (item) => {
    const newsData = { ...item };
    props.setGeneralDetails(newsData);
    props.channgeScreen(item);
  };
  return (
    <div className="col-lg-4">
      {props.eventList.length > 0 ? (
        <div className="related-post-area">
          <h3>Related Post</h3>
          <div className="related-post-box">
            {props.eventList
              .sort(() => Math.random() - 0.5)
              .map((item, key) => {
                return (
                  <div className="related-post-content" key={key}>
                    <Link
                      to={`/event/${item.Slug}`}
                      onClick={() => bottomClicked(item)}
                    >
                      <img src={`${item?.ImagePath !== null ?
                          item?.ImagePath?.includes("simplefileupload") ? item?.ImagePath :
                              `${serverLink}public/uploads/${shortCode}/event_uploads/${item?.ImagePath}` : imgF}`} alt="Image" />
                    </Link>
                    <h4>
                      <Link
                        to={`/event/${item.Slug}`}
                        onClick={() => bottomClicked(item)}
                      >
                        {item.Title}{" "}
                      </Link>
                    </h4>
                    <p>
                      <i className="flaticon-tag" /> {item.FacultyCode}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
