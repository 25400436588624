import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../images/logo.png";
import
{
  formatDateAndTime,
  projectAddress, projectFacebook, projectInstagram, projectLinkedIn,
  projectName,
  projectPhone, projectTwitter,
} from "../../../resources/url";

export default function Footer()
{
  return (
    <>
      <div className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-logo-area">
                <Link to={"/"}>
                  <img src={Logo} alt={projectName} height={"100px"} />
                </Link>
                <p>{projectAddress}</p>
                <div className="contact-list">
                  <ul>
                    <li>
                      <a href={`tel:${projectPhone}`}>{projectPhone}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet">
                <h3>Quick Links</h3>
                <div className="list">
                  <ul>
                    <li>
                      <Link to={"/about-us"}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/our-campus"}>
                        Our Campus
                      </Link>
                    </li>
                    <li>
                      <Link to={"/board-members"}>
                        Leadership of the University
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"}>
                        Map and Contact
                      </Link>
                    </li>
                    <li>
                      <Link to={"/press"}>
                        Baba-Ahmed in the Media
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet">
                <h3>Resources</h3>
                <div className="list">
                  <ul>
                    <li>
                      <Link to={"/admission/course-finder"}>
                        All Courses
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admission/course-finder"}>
                        Course Finder
                      </Link>
                    </li>
                    <li>
                      <Link to={"/admission/application"}>
                        Apply Now
                      </Link>
                    </li>
                    <li>
                      <Link to={"/staff-list"}>
                        Staff List
                      </Link>
                    </li>
                    <li>
                      <Link to={"/publication"}>
                        Publications
                      </Link>
                    </li>
                    <li>
                      <Link to={"/jobs"}>
                        Careers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widjet">
                <h3>Academics</h3>
                <div className="list">
                  <ul>
                    <li>
                      <Link to={"/education"}>
                        Education
                      </Link>
                    </li>
                    <li>
                      <Link to={"/learning-goal"}>
                        Learning Goal
                      </Link>
                    </li>
                    <li>
                      <Link to={"/academic-registry"}>
                        Academic Registry
                      </Link>
                    </li>
                    <li>
                      <Link to={"/news"}>
                        News
                      </Link>
                    </li>
                    <li>
                      <Link to={"/event"}>
                        Events
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="shape">
            <img src="/assets/images/shape-1.png" alt="Image" />
          </div>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-lg-6 col-md-4">
                <div className="social-content">
                  <ul>
                    <li>
                      <span>Follow Us On</span>
                    </li>
                    <li>
                      <a href={projectFacebook} target="_blank">
                        <i className="ri-facebook-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a href={projectTwitter} target="_blank">
                        <i className="ri-twitter-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a href={projectInstagram} target="_blank">
                        <i className="ri-instagram-line"></i>
                      </a>
                    </li>
                    <li>
                      <a href={projectLinkedIn} target="_blank">
                        <i className="ri-linkedin-fill"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-8">
                <div className="copy">
                  <p>
                    {formatDateAndTime(new Date(), 'year_only')} © {projectName} All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="go-top" onClick={() => window.scrollTo(0, 0)}>
        <i className="ri-arrow-up-s-line"></i>
        <i className="ri-arrow-up-s-line"></i>
      </div>
    </>
  );
}
