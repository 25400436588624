import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import
{
  setApplicationDataDetails,
  setApplicationLoginDetails,
} from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import { projectName } from "../../../../resources/url";

function Sidebar(props)
{
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState("");
  const handleLogout = () =>
  {
    props.setOnApplicationLoginDetails("");
    props.setOnApplicationDataDetails("");
    navigate("/admission/application");
  };

  useEffect(() =>
  {
    if (location.pathname === '/admission/application/undergraduate')
      setActive('dashboard')
    else if (location.pathname === '/admission/application/undergraduate/personal-information')
      setActive('info')
    else if (location.pathname === '/admission/application/undergraduate/secondary-school-result')
      setActive('result')
    else if (location.pathname === '/admission/application/undergraduate/guardian-information')
      setActive('guardian')
    else if (location.pathname === '/admission/application/undergraduate/documents-uploads')
      setActive('document')
    else if (location.pathname === '/admission/application/undergraduate/document-preview')
      setActive('preview')
    else if (location.pathname === '/admission/application/undergraduate/jamb-result')
      setActive('jamb')
    else
      setActive('dashboard')
  }, [location])

  return (
    <div className="col-lg-3">
      <div className="additional-information">
        <h3>Welcome, {props.userData.FirstName}</h3>
        <div className="list text-start">
          {
            props.type === "update" ?
              <ul>
                <li className={active === 'jamb' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/update-jamb-result"}>
                    <span className={active === 'jamb' ? 'text-white' : ''}>Jamb Result</span>
                  </Link>
                </li>
              </ul>
              :
              <ul>
                <li className={active === 'dashboard' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate"}>
                    <span className={active === 'dashboard' ? 'text-white' : ''}>Dashboard </span>
                  </Link>
                </li>
                <li className={active === 'info' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/personal-information"}>
                    <span className={active === 'info' ? 'text-white' : ''}>Personal Information</span>
                  </Link>
                </li>
                <li className={active === 'result' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/secondary-school-result"}>
                    <span className={active === 'result' ? 'text-white' : ''}>O/A'Level Results</span>
                  </Link>
                </li>
                <li className={active === 'jamb' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/jamb-result"}>
                    <span className={active === 'jamb' ? 'text-white' : ''}>Jamb Result</span>
                  </Link>
                </li>

                {/*<li>*/}
                {/*  <Link to="#">A Level result (Optional)</Link>*/}
                {/*</li>*/}
                <li className={active === 'guardian' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/guardian-information"} >
                    <span className={active === 'guardian' ? 'text-white' : ''}>Guardian Information</span>
                  </Link>
                </li>
                <li className={active === 'document' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/documents-uploads"} >
                    <span className={active === 'document' ? 'text-white' : ''}>Document Upload</span>
                  </Link>
                </li>

                <li className={active === 'preview' ? 'default-btn bg-secondary' : ''}>
                  <Link style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/document-preview"} >
                    <span className={active === 'preview' ? 'text-white' : ''}>Preview</span>
                  </Link>
                </li>
              </ul>
          }
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-lg-12 col-sm-12">
          <button className="default-btn btn w-100" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) =>
{
  return {
    setOnApplicationLoginDetails: (p) =>
    {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnApplicationDataDetails: (p) =>
    {
      dispatch(setApplicationDataDetails(p));
    },
  };
};

export default connect(null, mapDispatchToProps)(Sidebar);
