import React from "react";
import { Link } from "react-router-dom";

export default function SideFilter(props) {
  const categoryClicked = async (item) => {
    const publicationData = { ...item };
    props.setGeneralDetails(publicationData);
  };
  const getCount = (pubType) => {
    return props.data.filter((e) => e.PublicationTypeID == pubType).length;
  };
  return (
    <div className="col-lg-3">
      <h4 className="text-uppercase">Faculties</h4>
      <Link
        to={"/publication"}
        onClick={() => props.updateData("")}
        style={{ fontWeight: "bold", fontsize: "14px", color: "#00B2FF" }}
      >
        All Publications ({props.data.length})
      </Link>
      <div className="related-post-area mt-1">
        <h5>Undergraduate</h5>
        <div className="related-post-box">
          <ul>
            {props.facultyList
              .filter(
                (e) =>
                  e.FacultyCode !== "PGS" &&
                  e.FacultyCode !== "CS" &&
                  e.FacultyCode !== "ENGR"
              )
              .map((items, key) => {
                return (
                  <li key={key}>
                    {" "}
                    <Link
                      to={`/publication`}
                      onClick={() => props.updateData("")}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {" "}
                      {items.FacultyName}{" "}
                    </Link>
                    (14)
                  </li>
                );
              })}
          </ul>

          <h5 className="mt-5">Postgraduate</h5>
          <ul>
            {props.facultyList
              .filter((e) => e.FacultyCode === "PGS")
              .map((items, key) => {
                return (
                  <li key={key}>
                    {" "}
                    <Link
                      to={`/faculty/${items.Slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {" "}
                      {items.FacultyName}{" "}
                    </Link>
                    (14)
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

      <div className="related-post-area">
        <h5>Publications By Type</h5>
        <div className="related-post-box">
          <ul>
            {props.publicationType.map((items, key) => {
              return (
                <li key={key}>
                  {" "}
                  <Link
                    to={"/publication"}
                    onClick={() => props.updateData(items.TypeName)}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {" "}
                    {items.TypeName} ({getCount(items.EntryID)})
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
