import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DattiBabaAhmed from "../../../images/general/baba-ahmed.jpeg";
import {formatDateAndTime, serverLink, shortCode} from "../../../resources/url";
import * as DOMPurify from 'dompurify';

function HomepageSectionNews(props) {
  const navigate = useNavigate();
  const onCourseChange = (e) => {
    e.preventDefault();
    navigate(`/course/${e.target.value}`);
  };

  return (
    <div className="podcasts-area pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="section-title style2">
              <h2>Latest News</h2>
            </div>
            {props.news_list.length > 0 &&
              props.news_list.map((news, index) => {
                if (index < 2) {
                  return (
                    <div key={index} className="single-podcasts-card">
                      <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5 mx-0 my-0">
                          <div className="" style={{ width: '250px', height: '250px', display: 'inline-block' }}>
                            <img style={{ maxWidth: '100%', maxHeight: '100%' }}
                              src={news.ImagePath !== null ?
                                news.ImagePath?.includes("simplefileupload") ? news.ImagePath :
                                  `${serverLink}public/uploads/${shortCode}/news_uploads/${news.ImagePath}` : ""}
                              alt="Image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                          <div className="podcast-content">
                            <span>
                              {formatDateAndTime(
                                news.InsertedDate,
                                "date_and_time"
                              )}
                            </span>
                            <h3>{news.Title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  DOMPurify.sanitize(news.Description.substring(0, 100)) + "...",
                              }}
                            />
                            <div className="play-icon">
                              <Link
                                to={`/news/${news.Slug}`}
                                className="popup-youtube play-btn"
                              >
                                <i className="ri-arrow-right-circle-line" />
                                <span>Read more</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div className="col-lg-4">
            <div className="subscribe-area">
              <div className="top-content">
                <i className="flaticon-college-graduation" />
                <h3>Find Course</h3>
              </div>
              <form className="newsletter-form" data-toggle="validator">
                <select
                  name=""
                  id=""
                  className="form-control"
                  onChange={onCourseChange}
                >
                  <option value="">Select Course</option>
                  {props.courseList.length > 0 &&
                    props.courseList.map((course, index) => {
                      return (
                        <option key={index} value={course.Slug}>
                          {course.CourseName}
                        </option>
                      );
                    })}
                </select>
              </form>
            </div>

            <div className="student-life-card">
              <img src={DattiBabaAhmed} alt="Datti Baba Ahmed" width={"100%"} />
              <div className="tags" style={{ opacity: 0.8 }}>
                <p>
                  Sen. Y.D. Baba- Ahmed, PhD <br /> (Founder, Pro Chancellor)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails,
  };
};

export default connect(mapStateToProps, null)(HomepageSectionNews);
