import React, { useEffect, useState } from "react";
import PageTitle from "../../../common/pagetitle/pagetitle";
import {
  EmailTemplates,
  formatDateAndTime,
  projectName,
  sendEmail,
  serverLink,
} from "../../../../resources/url";
import { Helmet } from "react-helmet";
import { setApplicationLoginDetails } from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import { decryptData, encryptData } from "../../../common/cryptography/cryptography";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import PageName from "../../../common/pagename/pagename";
import Loader from "../../../common/loader/loader";
import { showAlert } from "../../../../resources/constants";

function ApplicationLogin(props) {
  const navigate = useNavigate();
  const [formType, setFormType] = useState('Login')
  const [login, setLogin] = useState({
    EmailAddress: "",
    Password: "",
    RecoveryEmail: ""
  });

  const [dateline, setDateline] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDateline = async () => {
    await axios
      .get(`${serverLink}application/dateline`)
      .then((res) => {
        setDateline(res.data.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
      });
  };

  useEffect(() => {
    getDateline();
  }, []);

  useEffect(() => {
    if (props.applicationLogin !== "")
      navigate("/admission/application/dashboard");
  }, [props.applicationLogin]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (login.EmailAddress === "") {
      toast.error("Please enter your email address");
      return false;
    }

    if (login.Password === "") {
      toast.error("Please enter your password");
      return false;
    }

    await axios
      .post(`${serverLink}application/login`, login)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          toast.success("Login Successful. Please wait...");
          const dateOfBirth = data.data[0].DateOfBirth.split("T")[0];
          const sendData = {
            ...data.data[0],
            DateOfBirth: dateOfBirth,
          };

          setTimeout(() => {
            props.setOnApplicationLoginDetails(sendData);
          }, 2000);
        } else toast.error("Invalid login credential!");
      })
      .catch((error) => {
        toast.error("Network error. Check your connection and try again!");
      });
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    toast.info("please wait...")
    const token = "pa" + Math.floor(Math.random() * 999999999);
    const email = EmailTemplates('2', encryptData(token));

    await axios.get(`${serverLink}application/registration/${login.RecoveryEmail}`, { token: encryptData(token) }).then(async (res) => {
      if (res.data.length > 0) {
        await axios.patch(`${serverLink}application/forget_password/add_token/${login.RecoveryEmail}`, { token: encryptData(token) })
          .then((result) => {
            if (result.data.message === "success") {
              toast.success('An email have been sent to your mailbox, please check and proceed.')
              sendEmail(
                login.RecoveryEmail, email.subject, email.title, login.RecoveryEmail, email.body, '', ''
              )
            } else {
              // toast.error('Please try again.')
            }
          }).catch((e) => {
            console.log('NETWORK ERROR')
          })
      } else {
        showAlert("Error", "The email provided is not registered", "error")
      }

    })


  }

  const onEdit = (e) => {
    if (e.target.id === "EmailAddress")
      setLogin({
        ...login,
        [e.target.id]: e.target.value,
      });
    else
      setLogin({
        ...login,
        [e.target.id]: encryptData(e.target.value),
      });

    if (e.target.id === "RecoveryEmail") {
      setLogin({
        ...login,
        RecoveryEmail: e.target.value
      })
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <PageName page={"Application Login"} />
      <PageTitle
        title={"Login"}
        breadCrumbs={["Home", "Application", "Login"]}
      />

      <div className="login-area pt-100 pb-70">
        <div className="container">
          <div className="container">
            {formType === "Login" &&
              <div className="login">
                <h3>Login</h3>


                <form onSubmit={handleLogin}>
                  <div className="form-group">
                    <label htmlFor="EmailAddress">Email Address</label>
                    <input
                      type="email"
                      id="EmailAddress"
                      className="form-control"
                      placeholder="Email Address*"
                      onChange={onEdit}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Password">Password</label>
                    <input
                      type="password"
                      id="Password"
                      className="form-control"
                      placeholder="Password*"
                      onChange={onEdit}
                    />
                  </div>
                  {dateline.IsAlwaysOpen < 1 && (
                    <div className="alert alert-warning">
                      Application Dateline:{" "}
                      {formatDateAndTime(dateline.EndDate, "date")}
                    </div>
                  )}
                  {new Date(dateline.EndDate) > new Date() ? (
                    <button type="submit" className="default-btn btn active">
                      Login
                    </button>
                  ) : (
                    <div className="alert alert-danger">Application Closed</div>
                  )}

                  <Link to="/admission/application/register">
                    Not registered? Register here
                  </Link>
                  <br />
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    setFormType("ForgetPassword")
                  }}>Forgot Password</span>
                </form>
              </div>
            }

            {formType === "ForgetPassword" &&
              <div className="login">
                <h3>Forgot Password</h3>
                <form onSubmit={handleForgetPassword}>
                  <div className="form-group">
                    <label htmlFor="RecoveryEmail">Email Address</label>
                    <input
                      type="email"
                      id="RecoveryEmail"
                      className="form-control"
                      placeholder="Email Address*"
                      onChange={onEdit}
                    />
                  </div>
                  <button type="submit" className="default-btn btn active">
                    Reset Password
                  </button>

                  <Link to="/admission/application/register">
                    Not registered? Register here
                  </Link>
                  <br />
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} href="#" onClick={() => {
                    setFormType("Login")
                  }}>Login</span>
                </form>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLogin);
