import axios from "axios";
import React, { useEffect, useState } from "react";
import {serverLink, shortCode} from "../../../resources/url";
import PageTitle from "../../common/pagetitle/pagetitle";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./gallery.css";
import PageName from "../../common/pagename/pagename";

function Gallery() {
  const [galleryCat, setGalleryCat] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageMui, setGalleryImageMui] = useState([]);

  async function getGalleryImages() {
    await axios
      .get(`${serverLink}gallery/list`)
      .then((response) => {
        const data = response.data;
        let gallery_images = [];
        let Category = [];
        let Subcategory = [];
        data.map((gallery, index) => {
          gallery_images.push({
            title: gallery.ImageTitle, // require
            original:  `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
                          :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // require
            thumbnail: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
                        :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // optional
          });

          Category.push(gallery.CategoryName);
          Subcategory.push({
            subCatName: gallery.SubCategoryName,
            catName: gallery.CategoryName,
          });
        });
        setGalleryImages(data);
        setGalleryImageMui(gallery_images);

        let uniqueCategory = Category.filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
        setGalleryCat(uniqueCategory);

        let filteredSubCat = [];
        for (let i = 0; i < Subcategory.length; i++) {
          if (filteredSubCat.length === 0) {
            filteredSubCat.push(Subcategory[i]);
          } else {
            let check = filteredSubCat.filter(
              (item) =>
                Subcategory[i].subCatName === item.subCatName &&
                Subcategory[i].catName === item.catName
            );
            if (check.length < 1) {
              filteredSubCat.push(Subcategory[i]);
            }
          }
        }
        setSubCat(filteredSubCat);
      })
      .catch((error) => {
        console.log("SERVER ERROR", error);
      });
  }

  useEffect(() => {
    getGalleryImages();
  }, []);

  const handleGalleryChange = (e, type) => {
    let gallery_images = [];
    if (type === "cat") {
      if (e !== "all")
        galleryImages
          .filter((item) => item.CategoryName === e)
          .map((gallery) => {
            gallery_images.push({
              title: gallery.ImageTitle, // require
              original: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
                        :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // require
              thumbnail: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
                        :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // optional
            });
          });
      else
        galleryImages.map((gallery) => {
          gallery_images.push({
            title: gallery.ImageTitle, // require
            original: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
                        :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // require
            thumbnail: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
                        :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // optional
          });
        });
    } else {
      galleryImages
        .filter(
          (item) =>
            item.CategoryName === e.catName &&
            item.SubCategoryName === e.subCatName
        )
        .map((gallery) => {
          gallery_images.push({
            title: gallery.ImageTitle, // require
            original: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
            :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // require
            thumbnail: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath 
            :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // optional
          });
        });
    }
    setGalleryImageMui(gallery_images);
  };
  return (
    <>
      <PageName page={"Gallery"} />
      <PageTitle title={"Gallery"} breadCrumbs={["About", "Gallery"]} />
      <div className="container">
        <div className="row mt-5">
          <div className="col-3">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => handleGalleryChange("all", "cat")}
                  >
                    All
                  </a>
                </li>
                {galleryCat.length > 0 &&
                  galleryCat.map((cat, index) => {
                    return (
                      <li key={index} className="nav-item">
                        <a
                          className="nav-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#menu_item${index}`}
                          href="#"
                          aria-expanded="false"
                          onClick={() => handleGalleryChange(cat, "cat")}
                        >
                          {cat}
                          <i className="bi small bi-caret-down-fill" />{" "}
                        </a>
                        <ul
                          id={`menu_item${index}`}
                          className="submenu collapse"
                          data-bs-parent="#nav_accordion"
                          style={{}}
                        >
                          {subCat.filter((item) => item.catName === cat)
                            .length > 0 &&
                            subCat
                              .filter((item) => item.catName === cat)
                              .map((sub, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      className="nav-link"
                                      href="#"
                                      onClick={() =>
                                        handleGalleryChange(sub, "sub")
                                      }
                                    >
                                      {sub.subCatName}
                                    </a>
                                  </li>
                                );
                              })}
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </div>
          <div className="col">
            <ImageGallery items={galleryImageMui} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
