import React from "react";
import {formatDateAndTime, serverLink, shortCode} from "../../../resources/url";
import { Link } from "react-router-dom";
import { setGeneralDetails, setNewsDetails } from "../../../actions/setactiondetails";
import { connect } from "react-redux";
import imgFile from "../../../images/event.jpg";


function HomepageSectionEventArea(props) {


  const eventClicked = async (item) => {
    props.setOnGeneralDetails(item);
  };


  return (
    <div className="events-area bg-f4f6f9 ptb-50">
      <div className="container">
        <div className="section-title">
          <h2>Events</h2>
        </div>
        <div className="row justify-content-center">
          {props.event_list.length > 0 &&
            props.event_list.map((event, index) => {
              if (index < 3){
                return (
                    <div key={index} className="col-lg-4 col-md-6">
                      <div className="single-events-card">
                        <div className="events-image">
                          <Link to={`/event/${event.Slug}`}>
                            <img
                                style={{ height: "300px", width: "100%" }}
                                src={`${event?.ImagePath !== null ?
                                    event?.ImagePath?.includes("simplefileupload") ? event?.ImagePath :
                                        `${serverLink}public/uploads/${shortCode}/event_uploads/${event?.ImagePath}` : imgFile}`}                          onClick={() => eventClicked(event)}
                                alt="Image"
                            />
                          </Link>
                          <div className="date">
                        <span onClick={() => eventClicked(event)}>
                          {formatDateAndTime(event.InsertedDate, "day")}
                        </span>
                            <p>
                              {formatDateAndTime(event.InsertedDate, "short_month")}
                            </p>
                          </div>
                        </div>
                        <div className="events-content">
                          <div className="admin">
                            <p>
                              <Link to={`/event/${event.Slug}`} onClick={() => eventClicked(event)} >
                                <i className="flaticon-user" /> {event.InsertedBy}
                              </Link>
                            </p>
                          </div>
                          <Link to={`/event/${event.Slug}`} onClick={() => eventClicked(event)}>
                            <h4 className={"text-capitalize"}>
                              {event.Title.toLowerCase()}
                            </h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                );
              }
            })}
        </div>
        <div className="more-health-care text-center">
          <p>
            <Link to="/event" className="read-more-btn active">
              More on Events
              <i className="flaticon-next" />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnEventDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
  };
};

export default connect(null, mapDispatchToProps)(HomepageSectionEventArea);
