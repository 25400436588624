import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";

function JobListingSide(props) {
  
    return (
        <div className="col-lg-4">
            <div className="courses-right-content pl-20">
                <div className="serch-content">
                    <h3>Search
                    </h3>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={
                                (e)=>{
                                    props.SearchJob(e.target.value)
                                }
                            }
                            placeholder="Find Your Course"
                        />
                        <button type="submit" className="src-btn">
                            <i className="flaticon-search" />
                        </button>
                    </div>
                </div>

                {props.byFaculty.length > 0 && (
                    <div className="academics-list">
                        <h3>Categories</h3>
                        <ul>
                            <li onClick={props.setAllJobs}
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                All({props.jobList2.length})
                            </li>
                            {props.byFaculty.length > 0 &&
                                props.byFaculty.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <span style={{textDecoration:'underline', cursor:'pointer'}}
                                                onClick={
                                                    (e) => {
                                                        props.setJobsByCategories(item.Faculty)
                                                    }}>
                                                {props.FacultyList.filter(x => x.FacultyCode === item.Faculty)[0].FacultyName}({item.Count})
                                            </span>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                )}

            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        courseList: state.CourseListDetails,
    };
};

export default connect(mapStateToProps, null)(JobListingSide);
