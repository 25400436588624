import React from 'react'
import "./pop-up.css"

export default function PopUp(props) {
    return (
        <div className="sidebarModal modal modal right fade" id="pModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <button type="button" id="closeModal" className="close" data-bs-dismiss="modal"><i className="ri-close-line"></i>
                    </button>
                    <div className="modal-body">
                        <div className="contact-form text-center" style={{ textAlign: 'justify' }}>
                            <img style={{ width: "10%" }} src={require("../../../images/logo.png")} />

                            <div className='mt-4'>
                                <h6>Admission for</h6>
                                <h1>
                                    <b>{new Date().getFullYear()}/{new Date().getFullYear() + 1}</b>
                                </h1>
                                session is now <h3>OPEN</h3>
                            </div>
                            Ready to embark on your educational journey with us? We're thrilled that you're considering joining our vibrant academic community. <br /> <br /> Apply for admission now to take the first step towards your future success!

                            <br /><br />
                            <a href="/admission/application" className="btn btn-main-md text-decoration-underline"  >
                                Apply Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
