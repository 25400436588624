import SimpleFileUpload from "react-simple-file-upload";
import { simpleFileUploadAPIKey } from "../../resources/url";
const JobLogin = (props) => {


    return (
        <>
            <div className="sidebarModal modal right fade" id="sModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" id="closeModal" className="close" data-bs-dismiss="modal"><i className="ri-close-line"></i>
                        </button>
                        <div className="modal-body">
                            <div className="contact-form">
                                {props.formType === "Login" &&
                                    <>
                                        <div className="sidebar-content">
                                            <h3>Login</h3>
                                        </div>

                                        <form id="contactForm" onSubmit={props.Login}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" name="EmailAddress" className="form-control" required
                                                            data-error="Please enter your email"
                                                            placeholder="Your email address" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="password" name="Password" className="form-control" required
                                                            data-error="Please enter your password"
                                                            placeholder="Your Password" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="submit" className="default-btn">Proceed to Login<span></span>
                                                    </button>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end">
                                                        <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={props.formToggle} >Create Account</a>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={props.showRecoverPassword} >Recover Password</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                }

                                {
                                    props.formType === "Register" &&
                                    <>
                                        <div className="sidebar-content">
                                            <h3>Register</h3>
                                        </div>
                                        <form id="contactForm" onSubmit={props.Register}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="FirstName" className="form-control"
                                                            data-error="Please enter your email"
                                                            placeholder="First Name" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div> <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="MiddleName" className="form-control"
                                                            data-error="Please enter your email"
                                                            placeholder="Middle Name" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div> <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="Surname" name="Surname" className="form-control"
                                                            data-error="Please enter your email"
                                                            placeholder="Surname" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="email" name="EmailAddress" className="form-control" required
                                                            data-error="Please enter your email"
                                                            placeholder="Email address" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="PhoneNumber" className="form-control" required
                                                            data-error="Please enter your Phone Number"
                                                            placeholder="Phone Number" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="password" name="Password" className="form-control" required
                                                            data-error="Please enter your password"
                                                            placeholder="Password" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="password" name="ConfirmPassword" className="form-control" required
                                                            data-error="Please enter your password"
                                                            placeholder="Confirm Password" onChange={props.onEdit} onBlur={props.ValidatePassword} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="RegisterWithCV">
                                                            <strong>upload cv</strong>(optional)
                                                            <strong className="text-danger" ><small> &emsp;(File size must not exceed 2mb)</small></strong>
                                                        </label>
                                                        <input type="file" name="RegisterWithCV" className="form-control"
                                                            onChange={props.onEdit} accept="application/pdf" />

                                                        {/* <SimpleFileUpload
                                                            maxFileSize={2}
                                                            apiKey={simpleFileUploadAPIKey}
                                                            tag="olivia-job-application"
                                                            onSuccess={props.handleFile}
                                                            preview="false"
                                                            width="100%"
                                                            height="100"
                                                        /> */}
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button disabled={props.errorLabel === true ? true : false} type="submit" className="default-btn">Create Account<span></span>
                                                    </button>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end">
                                                        <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={props.formToggle} >Login</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                }
                                {
                                    props.formType === "Recover" &&
                                    <>
                                        <div className="sidebar-content">
                                            <h3>Recover Password</h3>
                                        </div>
                                        <form id="contactForm" >
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="Email" className="form-control"
                                                            data-error="Please enter your email"
                                                            placeholder="Email Address" onChange={props.onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="submit" className="default-btn"
                                                        onClick={props.sendRecoveryMail}>Recover Password<span></span>
                                                    </button>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end">
                                                        <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={props.formToggle} >Login</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default JobLogin