import axios from "axios";
import React, { useEffect, useState } from "react";
import {serverLink, shortCode} from "../../../resources/url";
import PageTitle from "../../common/pagetitle/pagetitle";
import Loader from "../../common/loader/loader";
import PageName from "../../common/pagename/pagename";
import LeadershipProfileModal from "./profile-modal/modal";
import './profile-modal/modal.css'

const Management = () => {
    const [management, setManagement] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getManagement() {
            const result = await axios.get(`${serverLink}leadership/management/list`);
            setManagement(result.data);
            setLoading(false);
        }
        getManagement();
    }, []);

    const [members, setMembers] = useState({})
    // window.onclick(() => {
    //   let modal = document.getElementById("myModal");
    //   modal.style.display = "none";
    // })
    return (
        <>
            {loading ? <Loader /> : ""}
            <PageName page={"Management"} />
            <PageTitle
                title={"Management"}
                breadCrumbs={["Home", "Management"]}
            />
            <div className="container">
                <div className="col-12">
                    <h3 className="text-center my-5">Management</h3>
                    <div className="d-flex align-items-center align-content-between flex-column">
                        <div className="row">
                            {management.map((member, index) => (
                                <div
                                    className={`text-center ${index === 0 ? "col-md-12 d-block" : "col-md-4"
                                    }  `}
                                    key={index}
                                >
                                    <img
                                        src={member.ImagePath !== null ? member.ImagePath.includes("simplefileupload") ? member.ImagePath :  `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${member.ImagePath}` : ""}
                                        alt={member.FullName}
                                        className="img-thumbnail"
                                        style={{
                                            height: 400,
                                            width: 400,
                                        }}
                                    />
                                    <h4 className="my-2">
                                        {member.Title} {member.FullName}
                                    </h4>
                                    <h5 >{member.Designation}</h5>
                                    {/* <p style={{ cursor: 'pointer' }} className="text-center mb-5" id="myBtn" onClick={() => {
                    let modal = document.getElementById("myModal");
                    modal.style.display = "block";
                    setMember(board)
                  }}>View profile</p> */}
                                    <p style={{ cursor: 'pointer'}} className="text-center mb-5" data-bs-toggle="modal" data-bs-target="#pModal" onClick={() => {
                                        setMembers(member)
                                    }}>View profile</p>

                                </div>

                            ))}
                        </div>


                        <LeadershipProfileModal boardmember={members} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Management;