import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import { Link } from "react-router-dom";
import PageName from "../../common/pagename/pagename";

export default function Tuition()
{
  return (
    <>
      <PageName page={"Tuition Fees"} />
      <PageTitle
        title={` Tuition Fees `}
        breadCrumbs={["Admission", "Fees", "Tuition"]}
      />
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-4">Tuition Fee</h3>
              <p style={{ textAlign: "justify" }}>
                Depending on the student's payment preference, the tuition fee
                can either be paid per semester or per academic year. However, complete payment of tuition is required
                before the commencement of the exam period.
              </p>
              <h3 className="mb-4">Tuition Cost</h3>
              <p>
                Currently there is no distinction between the tuition cost for
                Domestic students (students of Nigerian nationality) and
                International students.
              </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center h3" colSpan={2}>
                      Undergraduate
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>Faculty of Science and Computing</td>
                    <td>N953,000  for 2 Semesters(i.e N476,000 per semester)</td>
                  </tr>
                  <tr>
                    <td>Faculty of Social and Management Sciences</td>
                    <td>N953,000 for 2 Semesters (i.e N476,000 per semester))</td>
                  </tr> */}
                  <tr>
                    <td colSpan={2} className="text-center">Kindly check on individual <Link to="/admission/course-finder" className="text-decoration-underline text-primary" >course page</Link> to confirm fees</td>
                  </tr>
                </tbody>
              </table>
              <h3 className="mb-4 mt-4">Tuition Revision</h3>
              <p style={{ textAlign: "justify" }}>
                The tuition cost is subject to revisions by the Senate of the
                university. This tuition change can be as a result of economic
                situations in the country or introduction of new systems that
                will lead to a more efficient learning environment. All students
                and parents/guardians will be informed of any new development
                regarding the tuition cost.
              </p>

              <h3 className="mb-4">Tuition and Admission</h3>
              <p style={{ textAlign: "justify" }}>
                Getting an admission at the university does not guarantee a
                place unless the tuition is paid. Thus, for incoming or transfer
                students, paying the tuition is part of the acceptance process
                into the university. The tuition cost only covers the cost of
                courses undertaken for the semester.
              </p>

              <h3 className="mb-4">Scholarship</h3>
              <p style={{ textAlign: "justify" }}>
                The university currently has no scholarship opportunities for
                students.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="detgails-right-content">
                <div className="academics-list">
                  <h3>QUICK LINKS</h3>
                  <ul>
                    <li>
                      <Link to={`/fees`}>
                        Fees <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/admission/course-finder`}>
                        Course Finder <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/education`}>
                        Education <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
