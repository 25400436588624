import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import { setUniversityInPressDetails } from "../../../actions/setactiondetails";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import PageTitle from "../../common/pagetitle/pagetitle";
import PressNewsContent from "./pressnewscontent";
import PageName from "../../common/pagename/pagename";
const XMLParser = require("react-xml-parser");

function UniversityInPress(props) {
  const [loading, setLoading] = useState(true);
  const [newsList, setNewsList] = useState(props.newsList);
  const universityCode = "Baba+Ahmed+University";

  const fetchNews = async () => {
    if (newsList.length > 0) {
      setLoading(false);
    }

    await axios
      .get(`${serverLink}news/press/${universityCode}`)
      .then((result) => {
        const data = result.data;
        const xml = new XMLParser().parseFromString(data);
        const xml_items = xml.children[0].children;
        const news_feed = xml_items.filter((item) => item.name === "item");
        const filtered_university = news_feed.filter((item) =>
          item.children[0].value.includes("Baba-Ahmed")
        );
        const filtered_university_tuition_fee = filtered_university.filter(
          (item) => !item.children[0].value.includes("Kano")
        );
        props.setOnUniversityInPressDetails(filtered_university_tuition_fee);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchNews();
  }, [""]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"University in the Press"} />
          <PageTitle
            title={`Baba Ahmed University In The Press`}
            breadCrumbs={["Resources", "University In The Press"]}
          />
          <div className="latest-news-area pt-100 pb-70">
            <div className="container">
              <h3 className="mb-4">Latest News</h3>
              <div className="row">
                {props.newsList.length > 0 &&
                  props.newsList.map((news, index) => {
                    return (
                      <PressNewsContent key={index} news={news.children} />
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    newsList: state.UniversityInPress,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnUniversityInPressDetails: (p) => {
      dispatch(setUniversityInPressDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UniversityInPress);
