import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageTitle from "../../common/pagetitle/pagetitle";
import SideSearch from "./section/sidebar";
import NewsDetailsContent from "./section/newsdetailscontent";
import {
  setGeneralDetails,
  setPublicationDetails,
} from "../../../actions/setactiondetails";
import EventSidebar from "../event/section/eventsidebar";
import PageName from "../../common/pagename/pagename";
import { propertyOf } from "lodash";
import { useParams } from "react-router-dom";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";

function NewsDetails(props) {
  const { slug } = useParams();
  const [newsData, setNewsData] = useState(
    props.newsList.length > 0 ?
      props.newsList.filter(x => x.Slug.toLowerCase() === slug.toLowerCase()) : []
  );
  const [newsList, setNewsList] = useState(props.newsList);
  const [change, setChange] = useState("");



  const changeScreen = (items) => {
    setChange(items);
  };

  useEffect(() => {
    getData()
  }, []);


  const getData = async () => {
    if (props.IsNews) {
      await axios.get(`${serverLink}news/all/active`).then((result) => {
        const data = result.data;
        setNewsData(data?.filter(x => x.Slug.toLowerCase() === slug.toLowerCase()))
        setNewsList(data);
      })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      await axios.get(`${serverLink}event/all/active`).then((result) => {
        const data = result.data;
        setNewsData(data?.filter(x => x.Slug.toLowerCase() === slug.toLowerCase()))
        setNewsList(data);
      })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }


  return (
    <>
      <PageName page={newsData[0]?.Title} />
      <PageTitle
        title={window.location.href.includes("news") ? "News Details" : "Event Details"}
        breadCrumbs={[
          "Resources",
          window.location.href.includes("news") ? "News" : "Event",
          window.location.href.includes("news") ? "News Details" : "Event Details",
        ]}
      />
      <div className="news-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            {
              newsData.length > 0 ? <NewsDetailsContent newsData={newsData[0]} IsNews={props.IsNews} />
                : <div className="col-lg-8"> <h3>Loading details...</h3> </div>
            }

            {
              newsList.length > 0 ?
                <>
                  {props.IsNews === true ? (
                    <SideSearch
                      newsList={newsList}
                      setGeneralDetails={props.setOnGeneralDetails}
                      channgeScreen={changeScreen}
                    />
                  ) : (
                    <EventSidebar
                      eventList={newsList}
                      setGeneralDetails={props.setOnGeneralDetails}
                      channgeScreen={changeScreen}
                    />
                  )}
                </>
                :
                <div className="col-lg-4"> <h3>Loading details...</h3> </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    newsData: state.GeneralDetails,
    newsList: state.NewsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
