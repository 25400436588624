export const subjects = [
  "Additional General Science",
  "Additional Mathematics",
  "Agricultural Science",
  "Applied Mathematics",
  "Animal Husbandry",
  "Biology",
  "Botany",
  "Chemistry",
  "CRS/IRS",
  "Commerce",
  "Computer Science",
  "English Language",
  "Economics",
  "Food Science",
  "Food and Nutrition",
  "Further Mathematics",
  "Fine and Applied Arts",
  "French",
  "General Biology",
  "General Science",
  "Government",
  "Geography",
  "Geology",
  "Geography",
  "Health Science",
  "Home management",
  "Human Biology",
  "Hygiene",
  "History",
  "Integrated Science",
  "Igbo",
  "Literature in English",
  "Mathematics",
  "Music",
  "Other Nigerian Languages",
  "Physics",
  "Pure and Applied Mathematics",
  "Principles of Accounts",
  "Rural Biology",
  "Rural Science",
  "Statistics",
  "Zoology",
  "Use of english",
  "Data processing",
  "Civic education",
  "Marketing",
  "Technical Drawing",
  "Fisheries",
  "General Mathematics",
  "Hausa",
  "Catering Craft Practice",
  "Office Practice"
];
