import React from "react";
import NewsLogo from "../../../images/newsLogo.jpg";
export default function PressNewsContent(props) {
    return ( <div className="col-lg-6 col-md-6">
                <div className="single-news-card">
                    <div
                        className="news-img"
                        style={{ maxHeight: "150px" }}
                    >
                        <a href={props.news[1].value} rel="noreferrer noopener" target="_blank">
                            <img src={NewsLogo} alt="Image"
                            />
                        </a>
                    </div>
                    <div className="news-content">
                        <div className="list">
                            <ul>
                                <li>
                                    <i className="flaticon-user" />
                                    {props.news[5].value}
                                </li>
                                <li>
                                    <i className="fa fa-calendar" />
                                    {props.news[3].value}
                                </li>
                            </ul>
                        </div>
                        <a href={props.news[1].value} rel="noreferrer noopener" target="_blank">
                            <h3 >
                                {props.news[0].value}
                            </h3>
                        </a>
                        <a href={props.news[1].value} rel="noreferrer noopener" target="_blank">

                <span >
                  {" "}
                    Read More
                  <i className="flaticon-next" />{" "}
                </span>
                        </a>
                    </div>
                </div>
            </div>
    );
}

