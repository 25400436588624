import React from "react";
import {
    projectAddress,
    projectEmail, projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectPhone,
    projectTwitter
} from "../../../resources/url";

export default function SidebarModal () {
    return (
        <div className="sidebarModal modal right fade" id="sidebarModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-bs-dismiss="modal"><i className="ri-close-line"></i>
                    </button>
                    <div className="modal-body">
                        <a href="index.html">
                            <img src="/assets/images/logo.png" className="main-logo" alt="logo"/>
                            <img src="/assets/images/white-logo.png" className="white-logo" alt="logo"/>
                        </a>
                        <div className="sidebar-content">
                            <h3>About Us</h3>
                            <p>
                                The philosophy of the Baba-Ahmed University is anchored on a zeal for excellence, professionalism and service as core values.
                            </p>
                            <div className="sidebar-btn">
                                <a href="/contact" className="default-btn">Let’s Talk</a>
                            </div>
                        </div>
                        <div className="sidebar-contact-info">
                            <h3>Contact Information</h3>
                            <ul className="info-list">
                                <li><i className="ri-phone-fill"></i> <a href="`tel:${projectPhone}`">{projectPhone}</a></li>
                                <li><i className="ri-mail-line"></i><a
                                    href="mailto:`${projectEmail}`"><span
                                    className="__cf_email__"
                                    data-cfemail="7a121f1616153a091b140f54191517">{projectEmail}</span></a>
                                </li>
                                <li><i className="ri-map-pin-line"></i> {projectAddress}</li>
                            </ul>
                        </div>
                        <ul className="sidebar-social-list">
                            <li>
                                <a href={projectFacebook} target="_blank"><i
                                    className="flaticon-facebook"></i></a>
                            </li>
                            <li>
                                <a href={projectTwitter} target="_blank"><i
                                    className="flaticon-twitter"></i></a>
                            </li>
                            <li>
                                <a href={projectLinkedIn}target="_blank"><i
                                    className="flaticon-linkedin"></i></a>
                            </li>
                            <li>
                                <a href={projectInstagram} target="_blank"><i
                                    className="flaticon-instagram"></i></a>
                            </li>
                        </ul>
                        {/*<div className="contact-form">*/}
                        {/*    <h3>Ready to Get Started?</h3>*/}
                        {/*    <form id="contactForm">*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col-lg-12 col-md-6">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <input type="text" name="name" className="form-control" required*/}
                        {/*                           data-error="Please enter your name" placeholder="Your name"/>*/}
                        {/*                    <div className="help-block with-errors"/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-12 col-md-6">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <input type="email" name="email" className="form-control" required*/}
                        {/*                           data-error="Please enter your email"*/}
                        {/*                           placeholder="Your email address"/>*/}
                        {/*                    <div className="help-block with-errors"/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-12 col-md-12">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <input type="text" name="phone_number" className="form-control" required*/}
                        {/*                           data-error="Please enter your phone number"*/}
                        {/*                           placeholder="Your phone number"/>*/}
                        {/*                    <div className="help-block with-errors"/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-12 col-md-12">*/}
                        {/*                <div className="form-group">*/}
                        {/*                      <textarea name="message" className="form-control" cols="30" rows="6"*/}
                        {/*                                required data-error="Please enter your message"*/}
                        {/*                                placeholder="Write your message..."></textarea>*/}
                        {/*                    <div className="help-block with-errors"></div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-12 col-md-12">*/}
                        {/*                <button type="submit" className="default-btn">Send Message<span></span>*/}
                        {/*                </button>*/}
                        {/*                <div id="msgSubmit" className="h3 text-center hidden"></div>*/}
                        {/*                <div className="clearfix"></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
