import React from "react";
import "../homepage.css";
import {formatDateAndTime, serverLink, shortCode} from "../../../resources/url";
import {Link} from "react-router-dom";
import imgFile from "../../../images/event.jpg";

function SectionUniversityEvents(props) {
    return (
        <div className="events-area  ptb-50">
            <div className="container">
                <div className="section-title">
                    <h2>Events</h2>
                </div>
                <div className="row justify-content-center">
                    {props.event_list.length > 0 &&
                    props.event_list.map((event, index) => {
                        if (index < 3){
                            return (
                                <div key={index} className="col-lg-4 col-md-6">
                                    <div className="single-events-card">
                                        <div className="events-image">
                                            <Link to={`/event/${event.Slug}`}>
                                                <img
                                                    src={`${event?.ImagePath !== null ?
                                                        event?.ImagePath?.includes("simplefileupload") ? event?.ImagePath :
                                                            `${serverLink}public/uploads/${shortCode}/event_uploads/${event?.ImagePath}` : imgFile}`}
                                                    alt="Image"
                                                />
                                            </Link>

                                        </div>
                                        <div className="events-content">
                                            <div className="row">
                                                <div className=" col-md-6 text-left">
                                                    <p>
                                                        <Link to={`/event/${event.Slug}`}>
                                                            <i className="fa fa-calendar" /> {event.InsertedBy}
                                                        </Link>
                                                    </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to={`/event/${event.Slug}`}>
                                                        <i className="flaticon-user " /> {event.InsertedBy}
                                                    </Link>
                                                </div>
                                            </div>


                                            <Link to={`/event/${event.Slug}`}>
                                                <h4>{event.Title}</h4>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }

                    })}
                </div>
                <div className="more-health-care text-center">
                    <p>
                        <Link to="/events" className="read-more-btn active">
                            More on Events
                            <i className="flaticon-next" />
                        </Link>
                    </p>
                </div>
            </div>
        </div>    )
}

export default SectionUniversityEvents;