import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DattiBabaAhmed from "../../../../images/general/baba-ahmed.jpeg";
import {formatDateAndTime, serverLink, shortCode} from "../../../../resources/url";
import * as DOMPurify from 'dompurify';

function FacultyNews(props) {
  const navigate = useNavigate();
  const onCourseChange = (e) => {
    e.preventDefault();
    navigate(`/course/${e.target.value}`);
  };

  return (
    <div className="podcasts-area pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="section-title style2">
              <h2>Latest News</h2>
            </div>
            {props.news_list.length > 0 ? (
              props.news_list.map((news, index) => {
                if (index < 2) {
                  return (
                    <div key={index} className="single-podcasts-card">
                      <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5">
                          <div className="podcasts-image">
                            <img
                              src={news.ImagePath !== null ? news.ImagePath.includes("simplefileupload") ? news.ImagePath :  `${serverLink}public/uploads/${shortCode}/news_uploads/${news.ImagePath}` : ""}
                              alt="Image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                          <div className="podcast-content">
                            <span>
                              {formatDateAndTime(
                                news.InsertedDate,
                                "date_and_time"
                              )}
                            </span>
                            <h3>{news.Title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  DOMPurify.sanitize(news.Description.substring(0, 100)) + "...",
                              }}
                            />
                            <div className="play-icon">
                              <Link
                                to={`/news/${news.Slug}`}
                                className="popup-youtube play-btn"
                              >
                                <i className="ri-arrow-right-circle-line" />
                                <span>Read more</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="alert alert-info">
                No upcoming news. Check back later!
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <div className="section-title">
              <h2>Events</h2>
            </div>
            <div className="row">
              {props.event_list.length > 0 ? (
                props.event_list.map((event, index) => {
                  return (
                    <div key={index} className="col-lg-12 col-md-12">
                      <div className="single-events-card">
                        <div className="events-image">
                          <div className="date">
                            <span>
                              {formatDateAndTime(event.InsertedDate, "day")}
                            </span>
                            <p>
                              {formatDateAndTime(
                                event.InsertedDate,
                                "short_month"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="events-content">
                          <div className="admin">
                            <p>
                              <Link to={`/event/${event.Slug}`}>
                                <i className="flaticon-user" />{" "}
                                {event.InsertedBy}
                              </Link>
                            </p>
                          </div>
                          <Link to={`/event/${event.Slug}`}>
                            <h4>{event.Title}</h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="alert alert-info">No upcoming event!</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails,
  };
};

export default connect(mapStateToProps, null)(FacultyNews);
