import React from "react";
import Computing from "../../../images/general/Olivia/computing.png";
import management from "../../../images/general/Olivia/management.png";
import medicine from "../../../images/general/Olivia/medicine.png";
import engineering from "../../../images/general/Olivia/engineering.png";
import pharmacy from "../../../images/general/Olivia/pharmacy.png";
import {serverLink} from "../../../resources/url";

export default function SectionUniversityFaculties() {
    return (
        <div className="students-stories-area bg-f4f6f9 pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>Our Faculties</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                         data-aos-delay="200" data-aos-once="true">
                        <div className="single-stories-card">
                            <img src={medicine} alt={"Faculty of Medicine and Health Sciences"} />
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                         data-aos-delay="400" data-aos-once="true">
                        <link to={`${serverLink}faculty/faculty-of-science-and-computing`}>
                        <div className="single-stories-card">
                            <img src={Computing} alt={"Faculty of Science and Computing"} />
                        </div>
                        </link>
                    </div>
                    <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                         data-aos-delay="600" data-aos-once="true">
                        <link to={`${serverLink}faculty/faculty-of-social-and-managment-science`}>
                        <div className="single-stories-card">
                            <img src={pharmacy} alt={"Faculty of Pharmaceutical Sciences"} />
                        </div>
                        </link>
                    </div>
                    <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                         data-aos-delay="400" data-aos-once="true">
                        <div className="single-stories-card">
                            <img src={management} alt={"Faculty of Management and Social Sciences"} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                         data-aos-delay="400" data-aos-once="true">
                        <div className="single-stories-card">
                            <img src={engineering} alt={"Faculty of Engineering and Technology"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
