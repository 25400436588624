import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
  setGeneralDetails,
  setNewsDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import {serverLink, shortCode} from "../../../resources/url";
import imgFile from "../../../images/event.jpg";
import PageTitle from "../../common/pagetitle/pagetitle";
import { Link } from "react-router-dom";
import PageName from "../../common/pagename/pagename";

function Event(props) {
  const [loading, setLoading] = useState(true);
  const [eventList, setEventList] = useState(props.eventList);

  const getData = async () => {
    await axios
      .get(`${serverLink}event/list/all/General`)
      .then((result) => {
        const data = result.data;
        setLoading(false);
        setEventList(data);
        props.setOnEventDetails(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getDateMonth = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);
    return monthNames[d.getMonth()];
  };

  const getDateDay = (date) => {
    const d = new Date(date);
    return d.getDay();
  };

  useEffect(() => {
    getData();
  }, [""]);

  const eventClicked = async (item) => {
    const EventData = { ...item };
    await props.setOnGeneralDetails(EventData);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Events"} />
          <PageTitle title={"Events"} breadCrumbs={["Resources", "Events"]} />

          <div className="events-area pt-100 pb-70">
            <div className="container">
              {eventList.length > 0 ? (
                <div className="row justify-content-center">
                  {eventList.map((items, key) => {
                    
                    return (
                      <div className="col-lg-4 col-md-6" key={key}>
                        <div className="single-events-card style-4">
                          <div className="events-image">
                            <Link to={`/event/${items.Slug}`}>
                              <img
                              style={{height : "300px", width : "100%"}}
                                src={`${items?.ImagePath !== null ?
                                    items?.ImagePath?.includes("simplefileupload") ? items?.ImagePath :
                                        `${serverLink}public/uploads/${shortCode}/event_uploads/${items?.ImagePath}` : imgFile}`}
                                onClick={() => eventClicked(items)}
                                alt="Image"
                              />
                            </Link>
                            <div className="date">
                              <span>{getDateDay(items.EventDate)}</span>
                              <p>{getDateMonth(items.EventDate)}</p>
                            </div>
                          </div>
                          <div className="events-content">
                            <div className="admin">
                              <p>
                                <i className="flaticon-student-with-graduation-cap" />{" "}
                                {items.InsertedBy}{" "}
                              </p>
                            </div>
                            <Link to={`/event/${items.Slug}`}>
                              <h3 onClick={() => eventClicked(items)}>
                                {items.Title}
                              </h3>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                "No Event Found!"
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    eventList: state.EventDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnEventDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Event);
