import React from "react";
import Computing from "../../../images/general/computing.png";
import Management from "../../../images/management-1.png";
import { serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";

export default function HomepageSectionOurFaculties() {
  return (
    <div className="campus-life-area pt-100 bg-f4f6f9">
      <div className="container">
        <div className="section-title">
          <h2>Our Faculties</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <Link to={`/faculty/faculty-of-science-and-computing`}>
              <div className="single-campus-card">
                <div className="img">
                  <img
                    src={Computing}
                    alt={"Faculty of Science and Computing"}
                  />
                </div>
                <div className="campus-content">
                  <h3>Faculty of Science and Computing</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-6">
            <Link to={`/faculty/faculty-of-social-and-managment-science`}>
              <div className="single-campus-card">
                <div className="img">
                  <img
                    src={Management}
                    alt={"Faculty of Social and Management Sciences"}
                  />
                </div>
                <div className="campus-content">
                  <h3>Faculty of Social and Management Sciences</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
