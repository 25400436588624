import React from "react";
import CountUp from "react-countup";

export default function SectionUniversityCount(props) {
    return (
        <div className="campus-information-area pb-50  bg-f4f6f9">
            <div className="container-fluid">
                <div className="counter mt-0 text-center" style={{backgroundColor: '#eeeeee', paddingTop: '35px'}}>
                    <div className="row">
                        <div className="col-lg-3 col-3">
                            <div className="counter-card">
                                <h1>
                        <span className="odometer">
                          <CountUp end={500} />
                        </span>
                                    <span className="target">+</span>
                                </h1>
                                <p>Initial Students</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-3">
                            <div className="counter-card">
                                <h1>
                        <span className="odometer">
                          <CountUp end={5} />
                        </span>
                                    <span className="target">+</span>
                                </h1>
                                <p>Faculties</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-3">
                            <div className="counter-card">
                                <h1>
                        <span className="odometer">
                          <CountUp end={props.departmentList} />
                        </span>
                                    <span className="target">+</span>
                                </h1>
                                <p>Departments</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-3">
                            <div className="counter-card">
                                <h1>
                        <span className="odometer">
                          <CountUp end={props.courseList} />
                        </span>
                                    <span className="target">+</span>
                                </h1>
                                <p>Courses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}