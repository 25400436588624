import { useNavigate } from "react-router-dom";
import stateData from "../../../../resources/state_and_lga.json";
import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../../resources/url";
import {
  setApplicationLoginDetails,
  setGeneralDetails,
} from "../../../../actions/setactiondetails";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "../../../common/loader/loader";

function Selection(props) {
  const navigate = useNavigate();
  const [userData] = useState(props.applicationLogin);
  const [appDetail, setAppDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState({
    ApplicationID: props.applicationData.EntryID,
    hostel: "",
    admissionType: "",
  });

  const [shouldSave, setShouldSave] = useState(false);

  const handleChange = (e) => {
    setSelection({ ...selection, [e.target.id]: e.target.value });
    setShouldSave(true);
  };

  const getData = async () => {
    await axios
      .get(
        `${serverLink}application/application-select/${props.applicationData.EntryID}`
      )
      .then((response) => {
        setAppDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let value in selection) {
      if (selection.hasOwnProperty(value) && value !== "hostel") {
        if (selection[value] === "") {
          toast.error(`${value} can't be empty`);
          return false;
        }
      }
    }

    if (shouldSave) {
      toast.info(`Submitting... Please wait!`);
      if (selection.hostel === "") setSelection({ ...selection, hostel: "No" });
      await axios
        .patch(`${serverLink}application/update-applicant`, selection)
        .then((res) => {
          if (res.data.message === "success") {
            toast.success(`Information Submitted Successfully`);
            onNext();
          } else
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  const onPrevious = () => {
    navigate("/admission/application/undergraduate");
  };

  const onNext = () => {
    navigate("/admission/application/undergraduate/personal-information");
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="graduate-admission pb-70">
      <div className="container">
        <div className="row">
          <Sidebar userData={userData} />
          <div className="col-9">
            <div className="register">
              <h3>Selection</h3>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="Gender">Do you want a hostel </label>
                      <select
                        id="hostel"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option value="">Select Option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <div className="form-group">
                      <label htmlFor="admissionType">
                        Admission Type <span className="text-danger">*</span>
                      </label>
                      <select
                        id="admissionType"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option value="">Select Option</option>
                        <option value="UTME">UTME</option>
                        <option value="Direct Entry">Direct Entry</option>
                        <option value="Transfer">Transfer</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  className="default-btn btn w-50 active"
                  onClick={onPrevious}
                >
                  Previous
                </button>
                <button type="submit" className="default-btn btn w-50 active">
                  Save and Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    applicant_data: state.GeneralDetails,
    applicationData: state.ApplicationDataDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Selection);
