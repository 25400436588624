import React, { useEffect, useState } from "react";
import Slider from "./section/slider";
import HomepageSectionAdmissionArea from "./section/section-admission-area";
import HomepageSectionEventArea from "./section/section-event";
import Loader from "../common/loader/loader";
import { connect } from "react-redux";
import {
    setEventDetails,
    setNewsDetails,
} from "../../actions/setactiondetails";
import axios from "axios";
import { projectName, serverLink } from "../../resources/url";
import PageName from "../common/pagename/pagename";
import SectionUniversityCount from "./section/section-university-count";
import SectionStartApplication from "./section/section-start-application";
import SectionNewsUpdates from "./section/section-news-updates";
import SectionUniversityFaculties from "./section/section-university-faculties";
import SectionUniversityContent from "./section/section-university-content";
import SectionUniversityMission from "./section/section-university-mission";
import SectionUniversityEvents from "./section/section-university-events";

function Homepage2(props) {
    const [loading, setLoading] = useState(true);
    const [slider, setSlider] = useState([]);
    const [newsList] = useState(props.newsList);
    const [eventList] = useState(props.eventList);

    const getData = async () => {
        await axios
            .get(`${serverLink}slider/homepage`)
            .then((result) => {
                const data = result.data;
                setSlider(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <PageName />
                    <Slider slider={slider} />
                    <SectionUniversityCount/>
                    <SectionStartApplication/>
                    <SectionNewsUpdates news_list={newsList} />
                    <SectionUniversityFaculties />
                    <SectionUniversityEvents  event_list={eventList}  />
                    <SectionUniversityContent/>
                    <SectionUniversityMission/>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        eventList: state.EventDetails,
        newsList: state.NewsDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnEventDetails: (p) => {
            dispatch(setEventDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage2);
