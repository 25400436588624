import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    EmailTemplates,
    sendEmail,
    formatDateAndTime,
    projectEmail,
    projectPhone,
    serverLink,
    shortCode,
} from "../../resources/url";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import Loader from "../common/loader/loader";
import axios from "axios";
import { formatDate } from "../common/dateformat/dateformat";
import JobLogin from "./login-modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { decryptData, encryptData } from "../common/cryptography/cryptography";
import JobApplicationPage from "./applicationpage";
import * as DOMPurify from 'dompurify';

function JobDetailsPage(props) {
    const navigate = useNavigate();

    const job_id = decryptData(window.location.href.split('/')[5])
    const [loading, setLoading] = useState(true);
    const [errorLabel, setErrorLabel] = useState(false)
    const [FacultyList, setFacultyList] = useState([])
    const [DepartmentList, setDepartmentList] = useState([])

    const [jobDetails, setJobDetails] = useState([])
    const [sorted, setSortedOpenings] = useState([])
    const [formType, setFormType] = useState("")

    const [account, setAccount] = useState({
        EmailAddress: "",
        PhoneNumber: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Password: "",
        ConfirmPassword: "",
        ePassword: "",
        RegisterWithoutCV: "",
        RegisterWithCV: ""

    })

    const showRecoverPassword = () => {
        setFormType("Recover")
    }

    const formToggle = () => {
        if (formType === "Login") {
            setFormType("Register")
        }
        else {
            setFormType("Login")
        }
    }

    const getJobDetails = async () => {
        await axios
            .get(`${serverLink}jobs/job-openings/list`)
            .then((result) => {
                const data = result.data;
                const filtered = data.filter(item => item.EntryID.toString() === job_id.toString())
                const sorted = data.sort(function (a, b) {
                    return b.ViewCount - a.ViewCount;
                });
                setJobDetails(filtered)
                setSortedOpenings(sorted)
            })
            .catch((error) => {
                console.log("netwrok error", error);
            });

        await axios.get(`${serverLink}jobs/faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setFacultyList(result.data)
                }
            }).catch((error) => {
                console.log("netwrok error", error);
            });

        await axios.get(`${serverLink}jobs/department/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setDepartmentList(result.data)
                    setLoading(false);
                }
            }).catch((error) => {
                console.log("netwrok error", error);
            });
    };

    const updateViewCount = async () => {
        await axios
            .post(`${serverLink}jobs/job-openings/update_view_count/${parseInt(job_id)}`)
            .then((result) => {

            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getJobDetails();
        updateViewCount();
    }, []);

    const onEdit = (e) => {
        setAccount(
            { ...account, [e.target.name]: e.target.value }
        )
        if (e.target.name === "Password") {
            setAccount({
                ...account,
                "ePassword": encryptData(e.target.value),
                Password: e.target.value
            })
        }
        if (e.target.name === "RegisterWithCV") {
            if (e.target.files[0].type !== "application/pdf") {
                setErrorLabel(true);
                toast.error("only pdf documents are allowed...")
            }
            else {
                setErrorLabel(false);
                setAccount({
                    ...account, [e.target.name]: e.target.files[0]
                })
            }
        }
        if (e.target.name === "Email") {
            setAccount({
                [e.target.name]: e.target.value
            })
        }
    }
    const sendRecoveryMail = async (e) => {
        e.preventDefault();
        try {
            if (account.Email === "") {
                toast.error("Please enter your email")
            } else {
                await axios.get(`${serverLink}jobs/password_recovery/${account.Email}`)
                    .then((result) => {
                        if (result.data.length > 0) {
                            let data = decryptData(result.data[0].Password);
                            const fData = {
                                EmailAddress: account.Email,
                                FirstName: result.data[0].FirstName,
                                Password: data

                            }
                            let email = EmailTemplates('1', fData);
                            sendEmail(
                                account.Email,
                                email.subject,
                                email.title,
                                result.data[0].FirstName,
                                email.body,
                                '',
                                ''
                            )
                            toast.success('An email have been sent to your email address, check to proceed...')
                        }
                    })
            }
        } catch (e) {
            console.log('NETWORK ERROR')
        }
    }
    const ValidatePassword = (e) => {
        setAccount({
            ...account, [e.target.name]: e.target.value
        })
        if (e.target.value.toString() === account.Password.toString()) {
            setErrorLabel(false)
        } else {
            toast.error('Password does not match')
            setErrorLabel(true)
        }
    }

    const Register = async (e) => {
        e.preventDefault();
        toast.info("creating account, please wait...");
        await axios
            .post(`${serverLink}jobs/job-openings/applicant/create_account`, account)
            .then((result) => {
                if (result.data.message === "success") {
                    if (account.RegisterWithCV !== "") {
                        const dt = new FormData();
                        dt.append("cv", account.RegisterWithCV)
                        dt.append("EmailAddress", account.EmailAddress);
                        axios.post(`${serverLink}jobs/job-openings/applicant/upload_cv`, dt)
                    }
                    toast.success("Account created successfully, proceed to login...");
                    sendEmail(
                        account.EmailAddress,
                        `${shortCode} Careers`,
                        `Account Creation`,
                        account.FirstName,
                        `
                        <div>
                        <p > Your Account have been created successfully; </p>
                            <p > Your Login details is below
                        <br/>
                                User ID : ${account.EmailAddress}  <br/>
                                Your Password is as chosen when creating account
                        <br/> 
                        <br/>
                                Proceed to login and continue your application
                        <br/>
                                Thank you
                            </p>
                        </div>
                        `,
                        '',
                        ''
                    )
                    setTimeout(() => {
                        document.getElementById("closeModal").click()
                    }, 500);
                    setAccount({
                        ...account,
                        PhoneNumber: "",
                        FirstName: "",
                        MiddleName: "",
                        Surname: "",
                        Password: "",
                        ConfirmPassword: "",
                        RegisterWithCV: ""
                    });
                    // }

                } else if (result.data.message === "exist") {
                    toast.error("Account already created, proceed to login");
                } else {
                    toast.error("Something went wrong, please try again");
                }
            })
            .catch((error) => {
                toast.error("Network error!, Try again later...");
            });
    }
    const Login = async (e) => {
        e.preventDefault();
        toast.info("Logging in, please wait...");
        await axios
            .post(`${serverLink}jobs/job-openings/applicant/login`, account)
            .then((result) => {
                if (result.data.message === "success") {
                    setAccount({
                        ...account,
                        EmailAddress: "",
                        Password: "",
                    });
                    const user = result.data.userData[0]
                    const userData = user.EmailAddress + "," + user.FirstName + "," + user.MiddleName + "," + user.Surname + "," + user.PhoneNumber + "," + user.CurriculumVitae
                    window.sessionStorage.setItem("userData", userData)
                    toast.success("Login successful");
                    setTimeout(() => {
                        document.getElementById("closeModal").click()
                    }, 500);
                } else {
                    toast.error("wrong login details");
                }
            })
            .catch((error) => {
                toast.error("Network error!, Try again later...");
            });

    }

    const handleFile = (url) => {
        setAccount({
            ...account, RegisterWithCV: url
        })
    }

    return loading ? (
        <Loader />
    ) : (
        <>
            <PageName page={"Job Details"} />
            <PageTitle
                title={jobDetails[0].Position}
                FacultyList={FacultyList}
                DepartmentList={DepartmentList}
                breadCrumbs={[
                    FacultyList.filter(x => x.FacultyCode === jobDetails[0].Faculty)[0].FacultyName,
                    DepartmentList.filter(x => x.DepartmentCode === jobDetails[0].Department)[0].DepartmentName,
                    jobDetails[0].Position]}
            />
            <div>
                <div className="graduate-admission pt-100 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8" style={{ textAlign: "justify" }}>
                                <div className="how-to-apply">
                                    <h2>Job Description</h2>
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jobDetails[0].Description) }}>
                                    </p>
                                    <div className="col-lg-12 col-md-12">
                                        <h2>Requirements</h2>
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jobDetails[0].Requirements) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="additional-information">
                                    <h3>Apply Now</h3>
                                    {jobDetails[0].Status === '1' && (
                                        <div className="alert alert-warning">
                                            Closing On : {formatDateAndTime(formatDate(jobDetails[0].ClosingDate), "date")}
                                        </div>
                                    )}
                                    <div className="list">
                                        <ul>
                                            {window.sessionStorage.getItem("userData") ?
                                                <li onClick={() => {
                                                    document.getElementById('apply').scrollIntoView(true)
                                                }}
                                                >
                                                    Apply
                                                </li>

                                                :
                                                <li data-bs-toggle="modal" onClick={() => {
                                                    setFormType('Login')
                                                }} data-bs-target="#sModal">
                                                    Login to Apply
                                                </li>
                                            }
                                            <Link to={"/jobs"}>
                                                <li style={{ backgroundColor: '#0f104d', color: 'white' }}>
                                                    <i className="fa fa-arrow-circle-o-left fa-lg" />&emsp;Back to Jobs</li>
                                            </Link>
                                            {window.sessionStorage.getItem("userData") &&
                                                <li onClick={() => {
                                                    window.sessionStorage.removeItem("userData");
                                                    // toast.success('Logout successful')
                                                    navigate('/jobs')
                                                }} >
                                                    Logout
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="related-download">
                                    <h3>Contact Us</h3>
                                    <ul>
                                        <li>
                                            <a href={`tel:${projectPhone}`}>
                                                <i className="flaticon-phone-call" />
                                                {projectPhone}
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`mailto:${projectEmail}`}>
                                                <i className="flaticon-email" />
                                                Send us mail
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {window.sessionStorage.getItem("userData") &&
                        <div>
                            {
                                jobDetails.length > 0 &&
                                <JobApplicationPage
                                    userData={window.sessionStorage.getItem("userData")}
                                    jobDetails={jobDetails[0]}
                                />
                            }
                        </div>
                    }
                </div>

                {sorted.length > 0 && (
                    <div className="program-area bg-f4f6f9 pt-100 pb-70">
                        <div className="container">
                            <div className="section-title">
                                <h2>Find Featured Jobs</h2>
                            </div>
                            <div className="row justify-content-center">
                                {sorted.map((item, index) => {
                                    return (
                                        <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                                            <div className="single-courses-card style4">
                                                <div className="courses-content">
                                                    <a href={`/jobs/details/${encryptData(item.EntryID.toString())}`}>
                                                        <h3>{item.Position}</h3>
                                                    </a>
                                                    <div className="bottom-content">
                                                        <ul>
                                                            <li>
                                                                {DepartmentList.filter(x => x.DepartmentCode === item.Department)[0].DepartmentName}
                                                            </li>
                                                            <li>
                                                                Closing on: {formatDate(item.ClosingDate)}
                                                            </li>
                                                            <ul className="d-flex justify-content-between">
                                                                <li>
                                                                    {item.Type}
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-eye" />
                                                                    {item.ViewCount}
                                                                </li>
                                                            </ul>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <JobLogin
                formType={formType}
                formToggle={formToggle}
                onEdit={onEdit}
                Login={Login}
                Register={Register}
                errorLabel={errorLabel}
                ValidatePassword={ValidatePassword}
                showRecoverPassword={showRecoverPassword}
                sendRecoveryMail={sendRecoveryMail}
                handleFile={handleFile}
            />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
    };
};
export default connect(mapStateToProps, null)(JobDetailsPage);
